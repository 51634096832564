<div class="no-gutters">
  <div class="row justify-content-center mb-lg-5 ">
    <div class="col-12 col-md-3 mt-5 pt-lg-5">
      <div class="joint-container">
        <div class="row">
          <div class="col">
            <img class="joint-image" src="{{ jointImageSrc }}" alt="" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="joint-name-2">
              {{ jointName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end mt-5 mb-5 pb-5 options">
    <div class="col-10 col-md-3 pl-4 pl-md-0 ml-md-0 mb-4">
      <div
        role="button"
        [routerLink]="['manuals']"
        [queryParams]="{ bodyPart: jointName, imgSrc: jointImageToManuals }"
        class=""
        (mouseover)="manualsChangeIcon = true"
        (mouseout)="manualsChangeIcon = false"
      >
        <div class="">
          <div class="manuals my-container">
            <img
              class="my-image manuals-image"
              src="../../../assets/secondaryNav/books-stack-of-three.png"
              alt=""
            />
          </div>
        </div>
        <div class="mt-2">
          <div class="option-desc ml-4">{{ 'manuals' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="col-10 col-md-3 pl-4 pl-md-0 ml-md-0 mb-4">
      <div
        role="button"
        [routerLink]="['videos']"
        [queryParams]="{ bodyPart: jointName }"
        class=""
        (mouseover)="videosChangeIcon = true"
        (mouseout)="videosChangeIcon = false"
      >
        <div class="my-container">
          <div class="videos">
            <img
              class="my-image vid"
              src="../../../assets/secondaryNav/85.png"
              alt=""
            />
          </div>
        </div>
        <div class="mt-3">
          <div class="ml-4 option-desc">{{ 'videos' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="col-10 col-md-3 pl-4 pl-md-0 ml-md-0 mb-4">
      <div
        role="button"
        [routerLink]="['evidences']"
        [queryParams]="{ bodyPart: jointName }"
        class=""
        (mouseover)="evidencesChangeIcon = true"
        (mouseout)="evidencesChangeIcon = false"
      >
        <div class="my-container ev">
          <div class="evidences">
            <img
              class="my-image ev-image"
              src="../../../assets/secondaryNav/document.png"
              alt=""
            />
          </div>
        </div>
        <div class="mt-3">
          <div class="option-desc ml-3 pb-5">
            {{ 'scientific' | translate }} <br />
            {{ 'evidence' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
