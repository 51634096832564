<div class="no-gutters">
  <app-joint-header [jointName]="jointName"> </app-joint-header>
  <div class="joint-white-container">
    <div
      *ngFor="let row of videos2DArray; let indexOfElement = index"
      class="row elements-row align-items-start"
    >
      <div
        *ngFor="let element of row; let rowIndex = index"
        class="col-md-12 col-lg-6 col-sm-12 col-xs-12"
      >
        <div role="button" (click)="goToVideoLink(element)" class="row">
          <div *ngIf="!twoElementsARow" class="col col-1 element-number">
            {{ indexOfElement + indexOfElement + rowIndex + 1 }}
          </div>
          <div *ngIf="twoElementsARow" class="col col-1 element-number">
            {{ indexOfElement + 1 }}
          </div>
          <div class="col col-3">
            <img
              class="element-thumbnail"
              src="{{
                apiService.getAssetsService().getThumbnailsUrl() +
                  element.thumbnailPath
              }}"
              alt=""
            />
          </div>
          <div class="col col-8">
            <div class="row">
              <h5 class="element-title">
                {{ element.title }}
              </h5>
            </div>
            <div class="row">
              <h6 class="element-description">
                {{ element.description }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
