<div class="home-container text-center">
  <div class="text-area mx-auto">
    <h1 class="welcome">{{ 'pleaseChooseAJoint' | translate }}</h1>

    <h3 class="description"></h3>
  </div>

  <div class="skeleton-container mx-auto">
    <img
      class="skeleton-img"
      src="../../../assets/skeleton/tr.png"
      alt="Skeleton"
    />
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Head', imgSrc: 'Joints-10.png' }"
      class="dot-container neck"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc">{{ 'head' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Hand', imgSrc: 'Joints-03.png' }"
      class="dot-container hand"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc">{{ 'hand' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Elbow', imgSrc: 'Joints-11.png' }"
      class="dot-container elbow"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc">{{ 'elbow' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Shoulder', imgSrc: 'Joints-07.png' }"
      class="dot-container shoulder"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc shoulder-desc">{{ 'shoulder' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Ankle', imgSrc: 'Joints-06.png' }"
      class="dot-container ankle"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc ankle-desc">{{ 'ankle' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Hip', imgSrc: 'Joints-04.png' }"
      class="dot-container hip"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc hip-desc">{{ 'hip' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Foot', imgSrc: 'Joints-12.png' }"
      class="dot-container foot"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc">{{ 'foot' | translate }}</p>
    </div>
    <div
      [routerLink]="['/joint']"
      [queryParams]="{ bodyPart: 'Knee', imgSrc: 'Joints-09.png' }"
      class="dot-container knee"
    >
      <img role="button" src="../../../assets/redSpot/Group 1654.png" alt="" />
      <p class="desc">{{ 'knee' | translate }}</p>
    </div>
  </div>
</div>
