<div [ngClass]="innerWidth > 767 ? '' : 'row'">
  <div
    [ngClass]="
      innerWidth > 767 ? 'split right' : 'col col-12 side-img-container'
    "
  >
    <img class="side-img" src="../../../assets/login/sideimage.png" />
    <h1 class="welcome">{{ 'welcomeToIJ' | translate }}</h1>
  </div>
  <div [ngClass]="innerWidth > 767 ? 'split left' : 'col col-12'">
    <div class="row align-items-center">
      <div class="col-6  logo">
        <img src="../../../assets/fidiaLogo/Group 1689.png" alt="Fidia Logo" />
      </div>
      <div class="col-6 languages-container">
        <img (click)="ApiService.getLangService().changeLang('en')"class=" languages "src="../assets/languages/united-kingdom (1).png" alt="">
        <img (click)="ApiService.getLangService().changeLang('fr')"class=" languages"src="../assets/languages/france (1).png" alt="">
      </div>
      </div>

    <div class="centered">
      <div class="login-text">{{ 'loginToYourAccount' | translate }}</div>
      <h1 class="login-text2">{{ 'email' | translate}}</h1>
      <input
        class="login-input form-control w-100"
        [(ngModel)]="email"
        type="text"
        placeholder="someone@email.com"
        required
      />
      <h4 *ngIf="error" class="error">{{ errorMessage }}</h4>
      <div class="buttons">
        <button (click)="login()" type="submit" class="login-button">
          {{ 'login' | translate}}
        </button>
        <button (click)="register()" type="submit" class="login-button">
          {{ 'register' | translate}}
        </button>
      </div>
    </div>
  </div>
  <!-- mz, -->
</div>
