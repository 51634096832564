<div class="no-gutters">
  <app-header
    *ngIf="apiService.getLangService().lang == 'en'"
    [pageImageSource1]="pageImageSrc1"
    [pageImageSource2]="pageImageSrc2"
    [pageName]="pageTitle"
  ></app-header>
  <app-header
    *ngIf="apiService.getLangService().lang != 'en'"
    [pageImageSource1]="pageImageSrc1"
    [pageImageSource2]="pageImageSrc2"
    [pageName]="pageTitleFr"
  ></app-header>
  <div class="no-gutters no-padding w-100">
    <div class="col-md-12 w-100 bottom-part">
      <div class="row w-100">
        <div class="col-lg-7 col-12 title-description">
          <div class="my-container">
            <div class="row">
              <div class="col">
                <h5 class="description">
                  Privately held, fully integrated Italian multinational company, with R&D, manufacturing, marketing and sales capabilities. The Company was founded in 1946 and is headquartered in Abano Terme (a short distance from Venice). Fidia’s overall objective is establishing its leadership, through an extensive product portfolio mainly based on hyaluronic acid (HA) in joint care, advanced wound care ophthalmology, aesthetic and autologous biological therapy, thereby providing patients and healthcare professionals with a variety of treatment options, such as pharmaceutical products, medical devices and food supplements. Over 55 years of R&D have placed Fidia at the forefront in the production of natural and functionalized HA, with different ranges of MW (1,100 patents). Manufacturing operations - located in Italy - are inspected and approved by major international health authorities, including the US and Korean FDA, the Brazilian ANVISA and G-MED Notified Body, and comply with the strictest international regulations and safety standards. Fidia extends its global reach through local partners in 100+ countries worldwide, as well as wholly-owned subsidiaries in USA, Germany, Austria, Spain, France, Russia, Czech Republic, Slovakia, Romania, Egypt and Middle East.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 details-section pr-md-5 pr-lg-4">
          <div class="row">
            <div class="col details">{{ "details" | translate }}</div>
          </div>
          <div class="row options-container justify-content-center">
            <div
              role="button"
              class="col-5 offset-1 options"
              (mouseover)="changePdfIcon = true"
              (mouseout)="changePdfIcon = false"
              (click) = "goToPdfLink()"
            >
              <img
                class="option-image"
                *ngIf="!changePdfIcon"
                src="../../../assets/icons/pdf.png"
                alt="Download pdf"
                style="width: 53px"
              />
              <img
                class="hover option-image"
                *ngIf="changePdfIcon"
                src="../../../assets/icons/pdf-1.png"
                alt="Download pdf"
                style="width: 53px"
              />
              <h5 class="view-company-text">View company profile</h5>
            </div>

            <div
              role="button"
              class="col-5 offset-1 options"
              (mouseover)="changeVideoIcon = true"
              (mouseout)="changeVideoIcon = false"
              (click)= "goToVideoLink()"
            >
              <img
                class="video-icon option-image"
                *ngIf="!changeVideoIcon"
                src="../../../assets/icons/128.png"
                alt="Video"
              />
              <img
                class="video-icon hover option-image"
                *ngIf="changeVideoIcon"
                src="../../../assets/icons/asset-1.png"
                alt=""
              />
              <h5 class="view-company-text">View company video</h5>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
