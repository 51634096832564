<div class="row align-items-center">
  <div [ngClass]="innerWidth > 520 ? 'col col-6' : 'col col-12'">
    <div class="container">
      <div class="row header-cont">
        <div class="col-12 col-md-3 col-lg-2">
          <div class="row py-3 mt-md-5 align-items-center">
            <div class="col-3 col-md-6">
              <img
                role="button"
                [routerLink]="['/login']"
                src="../../../assets/icons/back-arrow.png"
                alt=""
              />
            </div>
            <div class="col-9 col-md-6">
              <div class="row">
                <img src="../../../assets/fidiaLogo/Group -1.png" alt="" />
            </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center align-items-center full-height-desktop h-75"
      >
        <div class="col-12 pt-4 pb-5">
          <h1 class="welcome pb-4">{{ 'pleaseAnswerQuestions' | translate }}</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="col col-md-6 col-xs-12">
    <div class="centered">
      <h1 class="login-text2">{{ 'usingIAHA' | translate }}</h1>
      <div class="inlineRadio" *ngFor="let item of iaha">
        <input
          role="button"
          type="radio"
          [(ngModel)]="iahaSelected"
          name="iahaselect"
          value="{{ item.value }}"
        />
        {{ item.name }}
      </div>
      <br />
      <br />
      <h1 class="login-text2">{{ 'whichJoints' | translate }}</h1>
      <div class="row" *ngFor="let jointRow of joints">
        <div class="col" *ngFor="let joint of jointRow">
          <label>
            <input
              role="button"
              type="checkbox"
              [name]="joint.name"
              [(ngModel)]="joint.checked"
              value="{{ joint.id }}"
            />
            {{ joint.name }}
          </label>
        </div>
      </div>
      <br />
      <br />
      <h1 class="login-text2">{{ 'injectionsPerMonth' | translate }}</h1>
      <div class="inlineRadio" *ngFor="let item of injections">
        <input
          role="button"
          type="radio"
          [(ngModel)]="injectionsSelected"
          name="injections"
          value="{{ item.value }}"
        />
        {{ item.name }}
      </div>
      <h6 *ngIf="error" class="error">{{ errorMessage }}</h6>
      <button (click)="next()" type="submit" class="login-button">{{ 'next' | translate }}</button>
    </div>
  </div>
</div>
