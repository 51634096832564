<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />

    <title></title>
  </head>

  <body>
    <div class="no-gutters" id="wrapper">
      <app-header *ngIf="router.url == '/home'"></app-header>
      <!-- Sidebar -->
      <div
        *ngIf="
          innerWidth > 1024 &&
          router.url != '/signup' &&
          router.url != '/signup-data' &&
          router.url != '/login'
        "
        [style.background]="sidebarColor"
        class="sidebar flex-fill flex-column sidebar-wrapper pl-0 pl-lg-2 pl-xl-0"
        id="sidebar-wrapper"
      >
        <div class="list-group list-group-flush">
          <div class="languages-container">
            <div class="row pt-3">
              <div class="col-6">
                <img
                  role="button"
                  (click)="ApiService.getLangService().changeLang('en')"
                  class="languages"
                  src="../assets/languages/united-kingdom (1).png"
                  alt=""
                />
              </div>
              <div class="col-6">
                <img
                  role="button"
                  (click)="ApiService.getLangService().changeLang('fr')"
                  class="languages"
                  src="../assets/languages/france (1).png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="sidebar-heading text-center">
            <img
              *ngIf="router.url.substring(0, 6) == '/joint'"
              class="company-name"
              src="../assets/icons/logo-blue.svg"
              alt=""
            />
            <img
              *ngIf="!(router.url.substring(0, 6) == '/joint')"
              class="company-name"
              src="../assets/icons/logo-white.svg"
              alt=""
            />
          </div>
          <a
            [style.background]="listItemBackground"
            routerLink="home"
            class="list-group-item p-3"
          >
            <div class="row">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Home') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-4"
                src="../assets/mainNav/135.png"
                alt="Home"
                >home</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Home'"
                class="material-icons md-36 icons col col-4"
                alt="Home"
              >
                home</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Home')
                "
                class="col col-4 icons"
                src="../assets/icons/135.png"
                alt="Home"
                >home
              </mat-icon>
              <h3 [style.color]="companyNameColor" class="col col-8 icon-name">
                {{ "home" | translate }}
              </h3>
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="aboutus"
            class="list-group-item p-3"
          >
            <div class="row">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'About Us') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-4"
                src="../assets/mainNav/135.png"
                alt="About Us"
                >info</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'About Us'"
                class="material-icons icons col col-4"
                alt="About Us"
              >
                info</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'About Us')
                "
                class="col col-4 icons"
                src="../assets/icons/135.png"
                alt="About Us"
                >info
              </mat-icon>
              <h3 [style.color]="companyNameColor" class="col col-8 icon-name">
                {{ "aboutFidia" | translate }}
              </h3>
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="products"
            routerLinkActive="active"
            class="list-group-item p-3"
          >
            <div
              (mouseover)="changeIcon = true"
              (mouseout)="changeIcon = false"
              class="row"
            >
              <img
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Products') &&
                  !(router.url.substring(0, 6) == '/joint') &&
                  changeIcon == false
                "
                class="icons col col-4"
                src="../assets/icons/syringeWhite.svg"
                alt="Products"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Products' && changeIcon == false"
                class="icons col col-4"
                alt="Products"
                src="../assets/icons/syringeWhite.svg"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Products') &&
                  changeIcon == false
                "
                class="col col-4 icons"
                src="../assets/icons/syringeBlue.svg"
                alt="Products"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="changeIcon == true"
                class="col col-4 icons"
                src="../assets/icons/syringeGold.svg"
                alt="Products"
              />
              <h3 [style.color]="companyNameColor" class="col col-8 icon-name">
                {{ "products" | translate }}
              </h3>
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="conferences"
            class="list-group-item p-3"
          >
            <div class="row">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Conferences') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-4"
                src="../assets/mainNav/135.png"
                alt="Conferences"
                >event</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Conferences'"
                class="material-icons icons col col-4"
                alt="Conferences"
              >
                event</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Conferences')
                "
                class="col col-4 icons"
                src="../assets/icons/135.png"
                alt="Conferences"
                >event
              </mat-icon>
              <h3 [style.color]="companyNameColor" class="col col-8 icon-name">
                {{ "events" | translate }}
              </h3>
            </div>
          </a>
        </div>
      </div>

      <!-- Sidebar -->
      <div
        *ngIf="
          innerWidth <= 1024 &&
          innerWidth >= 700 &&
          router.url != '/signup' &&
          router.url != '/signup-data' &&
          router.url != '/login'
        "
        [style.background]="sidebarColor"
        class="sidebar flex-fill flex-column sidebar-wrapper"
        id="sidebar-wrapper"
      >
        <div class="list-group list-group-flush">
          <div class="languages-container">
            <div class="row mt-3">
              <div class="col-12 mb-3 text-center">
                <img
                  role="button"
                  (click)="ApiService.getLangService().changeLang('en')"
                  class="languages"
                  src="../assets/languages/united-kingdom (1).png"
                  alt=""
                />
              </div>
              <div class="col-12 text-center">
                <img
                  role="button"
                  (click)="ApiService.getLangService().changeLang('fr')"
                  class="languages"
                  src="../assets/languages/france (1).png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="company-name sidebar-heading text-center">
            <img src="../assets/icons/logo-white.svg" class="logo" />
          </div>
          <a
            [style.background]="listItemBackground"
            routerLink="home"
            class="list-group-item"
          >
            <div class="row justify-content-center">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Home') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-12"
                src="../assets/mainNav/135.png"
                alt="Home"
                >home</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Home'"
                class="material-icons md-36 icons col col-12"
                alt="Home"
              >
                home</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Home')
                "
                class="col col-12 icons"
                src="../assets/icons/135.png"
                alt="Home"
                >home
              </mat-icon>
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="aboutus"
            class="list-group-item"
          >
            <div class="row justify-content-center">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'About Us') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-12"
                src="../assets/mainNav/135.png"
                alt="About Us"
                >info</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'About Us'"
                class="material-icons icons col col-12"
                alt="About Us"
              >
                info</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'About Us')
                "
                class="col col-12 icons"
                src="../assets/icons/135.png"
                alt="About Us"
                >info
              </mat-icon>
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="products"
            routerLinkActive="active"
            class="list-group-item"
          >
            <div
              (mouseover)="changeIcon = true"
              (mouseout)="changeIcon = false"
              class="row justify-content-center"
            >
              <img
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Products') &&
                  !(router.url.substring(0, 6) == '/joint') &&
                  changeIcon == false
                "
                class="icons col-12"
                src="../assets/icons/syringeWhite.svg"
                alt="Products"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Products' && changeIcon == false"
                class="icons col col-6"
                alt="Products"
                src="../assets/icons/syringeWhite.svg"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Products') &&
                  changeIcon == false
                "
                class="col col-6 icons"
                src="../assets/icons/syringeBlue.svg"
                alt="Products"
              />
              <img
                [style.color]="companyNameColor"
                *ngIf="changeIcon == true"
                class="col col-6 icons"
                src="../assets/icons/syringeGold.svg"
                alt="Products"
              />
            </div>
          </a>
          <a
            [style.background]="listItemBackground"
            routerLink="conferences"
            class="list-group-item"
          >
            <div class="row justify-content-center">
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  !(currentRoute == 'Conferences') &&
                  !(router.url.substring(0, 6) == '/joint')
                "
                class="icons col col-12"
                src="../assets/mainNav/135.png"
                alt="Conferences"
                >event</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="currentRoute == 'Conferences'"
                class="material-icons icons col col-12"
                alt="Conferences"
              >
                event</mat-icon
              >
              <mat-icon
                [style.color]="companyNameColor"
                *ngIf="
                  router.url.substring(0, 6) == '/joint' &&
                  !(currentRoute == 'Conferences')
                "
                class="col col-12 icons"
                src="../assets/icons/135.png"
                alt="Conferences"
                >event
              </mat-icon>
            </div>
          </a>
        </div>
      </div>

      <nav
        *ngIf="
          innerWidth < 700 &&
          router.url != '/signup' &&
          router.url != '/signup-data' &&
          router.url != '/login' &&
          router.url != '/'
        "
        [style.background]="navbarColor"
        class="navbar fixed-bottom navbar-light row"
        style="padding: 0rem 1rem !important"
      >
        <a
          [style.background]="listItemBackground"
          routerLink="home"
          class="list-group-item col-3"
        >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              !(currentRoute == 'Home') &&
              !(router.url.substring(0, 6) == '/joint')
            "
            class="icons"
            src="../assets/mainNav/135.png"
            alt="Home"
            >home</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="currentRoute == 'Home'"
            class="material-icons icons"
            alt="Home"
          >
            home</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              router.url.substring(0, 6) == '/joint' &&
              !(currentRoute == 'Home')
            "
            class="icons"
            src="../assets/icons/135.png"
            alt="Home"
            >home
          </mat-icon>
        </a>
        <a
          [style.background]="listItemBackground"
          routerLink="aboutus"
          class="list-group-item col-3"
        >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              !(currentRoute == 'About Us') &&
              !(router.url.substring(0, 6) == '/joint')
            "
            class="icons"
            src="../assets/mainNav/135.png"
            alt="About Us"
            >info</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="currentRoute == 'About Us'"
            class="material-icons icons"
            alt="About Us"
          >
            info</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              router.url.substring(0, 6) == '/joint' &&
              !(currentRoute == 'About Us')
            "
            class="icons"
            src="../assets/icons/135.png"
            alt="About Us"
            >info
          </mat-icon>
        </a>
        <!-- <a
        [style.background]="listItemBackground"
        routerLink="products"
        routerLinkActive="active"
        class="list-group-item"
      >
        <mat-icon
          [style.color]="companyNameColor"
          *ngIf="
            !(currentRoute == 'Products') &&
            !(router.url.substring(0, 6) == '/joint')
          "
          class="icons col col-7"
          src="../assets/mainNav/135.png"
          alt="Products"
          >medical_services
        </mat-icon>
        <mat-icon
          [style.color]="companyNameColor"
          *ngIf="currentRoute == 'Products'"
          class="material-icons icons col col-7"
          alt="Products"
        >
          medical_services
        </mat-icon>
        <mat-icon
          [style.color]="companyNameColor"
          *ngIf="
            router.url.substring(0, 6) == '/joint' &&
            !(currentRoute == 'Products')
          "
          class="col col-7 icons"
          src="../assets/icons/135.png"
          alt="Products"
          >medical_services
        </mat-icon>
      </a> -->
        <a
          [style.background]="listItemBackground"
          routerLink="products"
          routerLinkActive="active"
          class="list-group-item col-3 p-4"
        >
          <img
            [style.color]="companyNameColor"
            *ngIf="
              !(currentRoute == 'Products') &&
              !(router.url.substring(0, 6) == '/joint') &&
              changeIcon == false
            "
            class="icons"
            src="../assets/icons/syringeWhite.svg"
            alt="Products"
          />
          <img
            [style.color]="companyNameColor"
            *ngIf="currentRoute == 'Products' && changeIcon == false"
            class="icons"
            alt="Products"
            src="../assets/icons/syringeWhite.svg"
          />
          <img
            [style.color]="companyNameColor"
            *ngIf="
              router.url.substring(0, 6) == '/joint' &&
              !(currentRoute == 'Products') &&
              changeIcon == false
            "
            class="icons"
            src="../assets/icons/syringeBlue.svg"
            alt="Products"
          />
          <img
            [style.color]="companyNameColor"
            *ngIf="changeIcon == true"
            class="icons"
            src="../assets/icons/syringeGold.svg"
            alt="Products"
          />
        </a>
        <a
          [style.background]="listItemBackground"
          routerLink="conferences"
          class="list-group-item col-3"
        >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              !(currentRoute == 'Conferences') &&
              !(router.url.substring(0, 6) == '/joint')
            "
            class="icons"
            src="../assets/mainNav/135.png"
            alt="Conferences"
            >event</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="currentRoute == 'Conferences'"
            class="material-icons icons"
            alt="Conferences"
          >
            event</mat-icon
          >
          <mat-icon
            [style.color]="companyNameColor"
            *ngIf="
              router.url.substring(0, 6) == '/joint' &&
              !(currentRoute == 'Conferences')
            "
            class="icons"
            src="../assets/icons/135.png"
            alt="Conferences"
            >event
          </mat-icon>
        </a>
      </nav>

      <div
        *ngIf="ApiService.getAuthenticationService().isAuthenticated()"
      ></div>

      <!-- Page Content -->
      <div class="myclass" id="page-content-wrapper no-gutters">
        <router-outlet></router-outlet>
      </div>

      <!-- /#page-content-wrapper -->
    </div>
    <!-- /#wrapper -->
  </body>
</html>
