<div class="header-container">
  <div class="logo">
    <img src="../../../assets/fidiaLogo/Group 1689.png" alt="Fidia Logo" />
  </div>
  <div *ngIf= "innerWidth<=700" class="languages-conatiner">
    <img (click)="apiService.getLangService().changeLang('en')"class=" languages "src="../assets/languages/united-kingdom (1).png" alt="">
    <img (click)="apiService.getLangService().changeLang('fr')"class=" languages"src="../assets/languages/france (1).png" alt="">
  </div>

  <div class="page-name">
    {{ pageName }}
  </div>
</div>

<div *ngIf="router.url != '/home'" class="row">
  <div class="col">
    <img
      class="w-100 image1"
      style="background-size: contain"
      src="{{ pageImageSource1 }}"
      alt=""
    />
    <img
      class="w-100 image2"
      style="background-size: contain"
      src="{{ pageImageSource2 }}"
      alt=""
    />
  </div>
</div>
<!-- </div> -->
