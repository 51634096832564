<div class="no-gutters">
  <app-joint-header [jointName]="jointName">

  </app-joint-header>
  <div class="joint-white-container">
    <div class="row">
      <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
        <div class="row">
          <div class="col title">
            {{title}}
          </div>
        </div>
        <div class="row description">
          <div [innerHtml]="htmlFile">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12">
        <div class="right-side-container">
          <div class="row joint-image-container">
            <div class="col">
              <img class="joint-image"src="{{jointImageSrc}}" alt="">
            </div>
          </div>
          <div class="row w-100">
            <div class="col ellipse w-100">
            </div>
          </div>
          <div class="row">
            <div class="col joint-image-name">
              {{ jointName }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

