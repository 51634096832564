<div class="no-gutters">
  <app-header *ngIf = "apiService.getLangService().lang =='en'"
    [pageImageSource1]="pageImageSrc1"
    [pageImageSource2]="pageImageSrc2"
    [pageName]="pageTitle"
  ></app-header>
  <app-header *ngIf = "apiService.getLangService().lang !='en'"
    [pageImageSource1]="pageImageSrc1"
    [pageImageSource2]="pageImageSrc2"
    [pageName]="pageTitleFr"
  ></app-header>

  <div class="row dot">
    <div class="col-md-12 rounded-circle thank-you-container">
      <div class="row">
        <img
          class="thank-you-image"
          src="../../../../assets/icons/111.png"
          alt="Thumbs Up"
        />
      </div>
      <div class="row thank-you">{{ 'thankYou' | translate}}!</div>
      <div class="row description">
        Your response has been recorded. <br />
        Thank you for your time.
      </div>
    </div>
  </div>
</div>
