<div class="no-gutters">
  <div role="button" class="row my-header">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-sm-12 col-lg-5">
        <div class="joint-name">
          {{ jointName }}
        </div>
      </div>
      <div class="col-sm-12 col-lg-7">
        <div
          class="row menu-options justify-content-center align-items-center pl-2 pr-4 mb-4 mb-lg-0"
        >
          <div
            class="col-4 text-left"
            [routerLink]="['/joint/manuals']"
            [queryParams]="{ bodyPart: jointName, imgSrc: jointImageToManuals }"
          >
            <div class="row align-items-center justify-content-start">
              <img
                *ngIf="router.url.substring(7, 14) == 'manuals'"
                class="menu-icons"
                src="../../../../assets/secondaryNav/Active/books-stack-of-three.png"
                alt=""
              />
              <img
                *ngIf="router.url.substring(7, 14) != 'manuals'"
                class="menu-icons"
                src="../../../../assets/secondaryNav/books-stack-of-three.png"
                alt=""
              />

              <h3
                [ngClass]="
                  router.url.substring(7, 14) == 'manuals'
                    ? 'desc-active'
                    : 'my-desc'
                "
                class="mt-2 text-center"
              >
                {{ 'manuals' | translate }}
              </h3>
            </div>
          </div>
          <div
            class="col-4 text-center"
            [routerLink]="['/joint/videos']"
            [queryParams]="{ bodyPart: jointName, imgSrc: jointImageToManuals }"
          >
            <div class="row align-items-center justify-content-center">
              <img
                *ngIf="router.url.substring(7, 13) == 'videos'"
                class="menu-icons"
                src="../../../../assets/secondaryNav/Active/85.png"
                alt=""
              />
              <img
                *ngIf="router.url.substring(7, 13) != 'videos'"
                class="menu-icons"
                src="../../../../assets/secondaryNav/85.png"
                alt=""
              />
              <h3
                [ngClass]="
                  router.url.substring(7, 13) == 'videos'
                    ? 'desc-active'
                    : 'my-desc'
                "
                class="mt-2 text-center"
              >
              {{ 'videos' | translate }}
              </h3>
            </div>
          </div>
          <div
            class="ev col-4 text-center"
            [routerLink]="['/joint/evidences']"
            [queryParams]="{ bodyPart: jointName, imgSrc: jointImageToManuals }"
          >
            <div class="row align-items-center justify-content-end">
              <img
                *ngIf="router.url.substring(7, 16) == 'evidences'"
                class="menu-icons ev-icon"
                src="../../../../assets/secondaryNav/Active/document.png"
                alt=""
              />
              <img
                *ngIf="router.url.substring(7, 16) != 'evidences'"
                class="menu-icons ev-icon"
                src="../../../../assets/secondaryNav/document.png"
                alt=""
              />
              <h3
                [ngClass]="
                  router.url.substring(7, 16) == 'evidences'
                    ? 'desc-active'
                    : 'my-desc'
                "
                class="mt-2 text-center"
              >
              {{ 'evidences' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
